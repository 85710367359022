import React, { Component } from "react";
import Reveal from "react-reveal/Reveal/";
import ApplyForm from "./ApplyForm";

class Careers extends Component {
  render() {
    let jhonData = this.props.jhonData;
    return (
      <section className="contact-area" id="careers">
        <div className="container">
          <div className="row row-reverse">
            {jhonData.contact &&
              jhonData.contact.map((item) => {
                return (
                  <React.Fragment key={item.id}>
                    <div className="col-md-5">
                      <Reveal effect="fadeInLeft" duration={500}>
                        <div className="contact_info">
                          <h4>Careers</h4>
                          <p>
                            Family owned and operated trucking company is
                            expanding and currently looking to fill:{" "}
                            <b>Regional Hot Shot Driver (Great Lakes)</b>
                          </p>
                          <ul>
                            <li>
                              Flatbed, no loading, occasional tarping required
                            </li>
                            <li>
                              Must be familiar with North American cargo
                              securement standards
                            </li>
                            <li>
                              Transport freight in a timely manner and comply
                              with ELD and Federal HOS regulations{" "}
                            </li>
                            <li>
                              Inspect vehicles for mechanical issues and safety
                              concerns
                            </li>
                            <li>
                              Excellent communication and customer service
                              skills
                            </li>
                            <li>
                              CDL Class-A with six months experience (willing to
                              provide some training)
                            </li>
                            <li>Clean driving record</li>
                            <li>Ability to pass drug/alcohol screening</li>
                            <li>
                              Home (most) weekends, flexible home time and time
                              off
                            </li>
                            <li>
                              Competitive pay, guaranteed base and additional
                              share of weekly truck gross revenue (1099)
                            </li>
                            <li>Personal use of company truck on weekends</li>
                          </ul>
                          {/* <ul className="nav">
                            {item.menuItems.map((info) => {
                              return (
                                <li className="item" key={info.id}>
                                  <div className="media">
                                    <a href="/#">
                                      <i className={info.icon}></i>
                                    </a>
                                    <div className="media-body">
                                      <a href="/#">{info.text}</a>
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                          </ul> */}
                        </div>
                      </Reveal>
                    </div>
                  </React.Fragment>
                );
              })}
            <div className="col-md-7">
              <Reveal effect="fadeInRight" duration={800}>
                <div className="input_form">
                  <h4>Quick Apply</h4>
                  <ApplyForm />
                  <div id="success">Your message succesfully sent!</div>
                  <div id="error">
                    Opps! There is something wrong. Please try again
                  </div>
                </div>
              </Reveal>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Careers;
