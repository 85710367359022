import React from "react";
import Sticky from "react-stickynode";
import { Link } from "react-scroll";
// import Login from "../../component/Login";
import { signInAPI } from "../../actions";
import { connect } from "react-redux";
import styled from "styled-components";
import { Redirect } from "react-router";

const Navbar = (props) => {
  var { mClass, mContainer, mainlogo, stickylogo } = props;

  let validUser = false;

  if (props.user) {
    if (
      props.user.displayName === "Matthew Bussen" ||
      props.user.displayName === "Pete Bussen"
    ) {
      validUser = true;
    }
  }

  return (
    <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
      {/* {props.user && <Redirect to="/LoadManager" />} */}
      {validUser === true && <Redirect to="/LoadManager" />}
      <nav className={`navbar navbar-expand-lg navbar-light ${mClass}`}>
        <div className={`container ${mContainer}`}>
          <a className="navbar-brand logo_h" href="./">
            <img
              src={require("../../image/" + mainlogo)}
              alt="Tomcat Transport Logo"
            />
            <img
              src={require("../../image/" + stickylogo)}
              alt="Tomcat Transport Logo"
            />
          </a>
          {/* <a
            href="./"
            onClick={() => props.signIn()}
            className="btn get-btn get-btn-two d-lg-none d-md-block login"
          >
            Login
          </a> */}
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <div
            className="collapse navbar-collapse offset"
            id="navbarSupportedContent"
          >
            <ul className="nav navbar-nav m-auto">
              <li className="nav-item">
                <Link
                  className="nav-link"
                  activeClass="active"
                  to="home"
                  spy={true}
                  smooth={true}
                  offset={-86}
                  duration={500}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  activeClass="active"
                  to="about"
                  spy={true}
                  smooth={true}
                  offset={-86}
                  duration={500}
                >
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  activeClass="active"
                  to="careers"
                  spy={true}
                  smooth={true}
                  offset={-86}
                  duration={500}
                >
                  Careers
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  activeClass="active"
                  to="service"
                  spy={true}
                  smooth={true}
                  offset={-86}
                  duration={500}
                >
                  Services
                </Link>
              </li>
              {/* <li className="nav-item">
                  <Link
                    className="nav-link"
                    activeClass="active"
                    to="testimonial"
                    spy={true}
                    smooth={true}
                    offset={-86}
                    duration={500}
                  >
                    Testimonials
                  </Link>
                </li> */}
              <li className="nav-item">
                <Link
                  className="nav-link"
                  activeClass="active"
                  to="contact"
                  spy={true}
                  smooth={true}
                  offset={-86}
                  duration={500}
                >
                  Contact
                </Link>
              </li>
            </ul>
            <ul className="nav navbar-nav navbar-right d-md-none d-lg-block">
              <li className="nav-item">
                <LoginButton className="login" onClick={() => props.signIn()}>
                  Login
                </LoginButton>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </Sticky>
  );
};

const LoginButton = styled.button``;

const mapStateToProps = (state) => {
  return {
    user: state.userState.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: () => dispatch(signInAPI()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
