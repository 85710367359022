import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Fade, Reveal } from "react-reveal/";
import { Redirect } from "react-router";

// function underConstruction() {
//   return <Redirect to="./Careers" />;
// alert(
//   "Thank you for your interest! Our site is currently under construction and we are busy building out its full functionality. Please contact us by filling out the Contact form below!"
// );
// }

class About extends Component {
  render() {
    let jhonData = this.props.jhonData;
    var { aClass } = this.props;
    return (
      <section className={`${aClass}`} id="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 d-flex align-items-center">
              <Fade bottom cascade duration={1000}>
                {/* I changed the div below to a main */}
                <main className="about_content">
                  <h2 className="t_color">{jhonData.aboutme}</h2>
                  <h6>{jhonData.role}</h6>
                  <p>{jhonData.aboutdetails}</p>
                  <h2 className="t_color">Now Hiring</h2>
                  <h6>CDL-A Drivers</h6>
                  <p>
                    Family owned and operated trucking company is expanding and
                    currently looking to fill:{" "}
                    <b>Regional Hot Shot Driver (Great Lakes)</b>
                  </p>
                  <a
                    href="#careers"
                    className="theme_btn active"
                    // onClick={underConstruction}
                  >
                    Apply Now
                  </a>
                  {/* <a
                    href="/TomcatTransport-CarrierPacket-7-13-21.pdf"
                    className="theme_btn"
                    download
                  >
                    Download Carrier Packet
                  </a> */}
                </main>
              </Fade>
            </div>
            <div className="col-lg-5">
              <div className="about_img">
                <Reveal effect="fadeInRight" duration={1500}>
                  <img
                    src={require("../image/tomcat-transport-patch.png")}
                    alt="Tomcat Transport Patch Style Logo"
                  />
                </Reveal>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default About;
