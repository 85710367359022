import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
/*-----pages-----*/
import { Home } from "./Home";
import LoadManager from "./LoadManager";
// import Careers from "./component/Careers";
import { NotFound } from "./404";
import { getUserAuth } from "./actions";
import { connect } from "react-redux";

function App(props) {
  useEffect(() => {
    props.getUserAuth();
  }, []);
  return (
    <React.Fragment>
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/LoadManager" component={LoadManager} />
          {/* <Route path="/careers" component={Careers} /> */}
          <Route component={NotFound} />
        </Switch>
      </Router>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  getUserAuth: () => dispatch(getUserAuth()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
