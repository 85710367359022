import React from "react";
import emailjs from "emailjs-com";

class ApplyForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {
        name: "",
        subject: "",
        phone: "",
        email: "",
      },
    };
  }

  handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    let errors = this.state.errors;

    switch (name) {
      case "name":
        errors.name = value.length === 0 ? "Enter your name" : "";
        break;
      case "location":
        errors.location =
          value.length < 5 ? "Location must be at least 5 characters" : "";
        break;
      case "experience":
        errors.experience =
          value.length < 5 ? "Experience must be at least 5 characters" : "";
        break;
      case "phone":
        errors.phone = value.length < 10 ? "10-digit with area code" : "";
        break;
      case "email":
        errors.email = value.length < 5 ? "Subject is not empty" : "";
        let appos = value.indexOf("@");
        let dots = value.lastIndexOf(".");

        if (appos < 1 || dots - appos < 2) {
          errors.email = "please enter valid email";
        }

        break;

      default:
        break;
    }
    this.setState({ errors, [name]: value });
  };

  submitHandler = (e) => {
    e.preventDefault();
    // if (
    //   this.state.errors.name.length === 0 &&
    //   this.state.errors.subject.length === 0 &&
    //   this.state.errors.phone.length === 0 &&
    //   this.state.errors.email.length === 0
    // ) {
    //   alert("form is valid");
    // } else {
    //   alert("form is invalid");
    // }
    emailjs
      .sendForm(
        "service_wd3eado",
        "template_7km9kxp",
        e.target,
        "user_pcIa7VVBbasptZm2o37sm"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert(
            "Thank you! Your Message has been sent! We will get back to you soon!"
          );
        },
        (error) => {
          console.log(error.text);
          alert("Form content is invalid. Your message was not sent.");
        }
      );
  };

  render() {
    const { errors } = this.state;
    return (
      <form onSubmit={this.submitHandler.bind(this)} className="form_class">
        <div className="row">
          <p>
            Please fill out the form below and we will reach out to you for more
            information as needed.
          </p>
          <div className="col-lg-6">
            <input
              type="text"
              id="name"
              name="name"
              className="form-control"
              placeholder="Your Name*"
              onChange={this.handleChange}
            />
            <p>{errors.name}</p>
          </div>
          <div className="col-lg-6">
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              placeholder="Your Email*"
              onChange={this.handleChange}
            />
            <p>{errors.email}</p>
          </div>
          <div className="col-lg-6">
            <input
              type="text"
              id="location"
              name="location"
              className="form-control"
              placeholder="Location* (City, State)"
              onChange={this.handleChange}
            />
            <p>{errors.location}</p>
          </div>
          <div className="col-lg-6">
            <input
              type="text"
              className="form-control"
              id="phone"
              name="phone"
              placeholder="Phone*"
              onChange={this.handleChange}
            />
            <p>{errors.phone}</p>
          </div>
          <div className="col-lg-6">
            <input
              type="text"
              className="form-control"
              id="experience"
              name="experience"
              placeholder="Experience* (years, months)"
              onChange={this.handleChange}
            />
            <p>{errors.experience}</p>
          </div>
          <div className="col-lg-2">
            <p>Start Date:</p>
          </div>
          <div className="col-lg-4">
            <input
              type="date"
              className="form-control"
              id="startdate"
              name="startdate"
              //   placeholder="Experience* (years, months)"
              onChange={this.handleChange}
            />
            <p>{errors.startdate}</p>
          </div>
          {/* <div className="col-lg-3">
            <label for="employed">Currently employed?</label>
          </div>
          <div className="col-lg-3">
            <select name="employed" id="employed">
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div> */}
          <div className="col-lg-12">
            <p>
              <b>
                Join our Waiting List for full sized flatbed driver positions
              </b>
            </p>
          </div>
        </div>

        {/* <textarea
          name="message"
          id="message"
          className="form-control"
          rows="6"
          placeholder="Your Message ..."
          onChange={this.handleChange}
        ></textarea> */}
        <button type="submit" className="btn send_btn theme_btn">
          Submit Application
        </button>
      </form>
    );
  }
}

export default ApplyForm;
